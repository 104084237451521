<template>
  <div>
    <div class="title font-weight-bold h6 mb-3">
      Mapa
    </div>
    <div class="mt-4">
      <z-map
        id="service-detail-map"
        :destinations="destinations"
        :incidences="incidences"
        :route="route"
        :calculate-route="calculateRoute"
        :center="center"
      />
    </div>
  </div>
</template>

<script>
import ZMap from "@zubut/common/src/components/ZMap";

export default {
  name: "SerivceDetailTabMap",

  components: { ZMap },

  props: {
    service: {
      type: Object,
      required: true
    },
    centerMap: {
      type: Object,
      required: false,
      default: null
    }
  },

  computed: {
    destinations() {
      return this.service.destinations;
    },

    center() {
      if (this.centerMap != null) {
        return this.centerMap;
      }
      if (this.destinations.length > 0) {
        return this.destinations[0].position;
      } else {
        return { lat: 20.6737777, lng: -103.4054536 };
      }
    },

    incidences() {
      return this.service.incidences.filter(i => i.position !== null);
    },

    route() {
      if (this.service && this.service.route && this.service.route.latLng) {
        return this.service.route.latLng;
      }
      return [];
    },

    calculateRoute() {
      return this.route.length === 0 && this.centerMap === null;
    }
  }
};
</script>

<style lang="scss" scoped>
#service-detail-map {
  height: 500px;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 10px;
}
</style>
